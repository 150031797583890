@font-face {
  font-family: 'PlusJakartaSans-Regular';
  src: url('assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: url('assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-BoldItalic';
  src: url('assets/fonts/PlusJakartaSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-ExtraBold';
  src: url('assets/fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-ExtraBoldItalic';
  src: url('assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-ExtraLight';
  src: url('assets/fonts/PlusJakartaSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-ExtraLightItalic';
  src: url('assets/fonts/PlusJakartaSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Italic';
  src: url('assets/fonts/PlusJakartaSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Light';
  src: url('assets/fonts/PlusJakartaSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-LightItalic';
  src: url('assets/fonts/PlusJakartaSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Medium';
  src: url('assets/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-MediumItalic';
  src: url('assets/fonts/PlusJakartaSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-SemiBold';
  src: url('assets/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-SemiBoldItalic';
  src: url('assets/fonts/PlusJakartaSans-SemiBoldItalic.ttf') format('truetype');
}

.bold {
  font-family: 'PlusJakartaSans-Bold', sans-serif;
}

.App {
  text-align: center;
  font-family: 'PlusJakartaSans-Regular', sans-serif;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.container {
  max-width: 960px;
}

/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
}

.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

/* Gradient Background */
.gradient-background {
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  color: white;
  /* Text color */
}

.blue-bg {
  background-color: #1e30f3;
}

.cta-btn {
  background-color: #1e30f3;
  border-radius: 7px;
  padding: 10px 20px 10px 20px;
  color: #FFF;
}

.cta-btn:hover {
  background-color: #FFF;
  border: 1px solid #1e30f3;
  color: #1e30f3;
  text-decoration: none;
}

/*
 * Extra utilities
 */

.flex-equal>* {
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal>* {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden {
  overflow: hidden;
}